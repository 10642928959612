import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import logo from '../assets/media/images/logo.png';
import QuoteDisplay from './QuoteDisplay';
import AddServices from './AddServices';
import Toast from './Toast'; // Import the Toast component
import NotFound from './NotFound';
import ContactUsSection from './ContactUsSection';

const Dashboard = () => {

    // Get the 'submissionID' parameter from the URL
    const { submissionID } = useParams();

    const [apidata, setApiData] = useState({});
    const [initialServiceList, setInitialServiceList] = useState([]);
    const [toastMessage, setToastMessage] = useState(null);
    const [isPageLoaded, setIsPageLoaded] = useState(false);
    const [isSubmissionIDValid, setIsSubmissionIDValid] = useState(null);

    const [oneTimeServices, setOneTimeServices] = useState({
        customerName: "",
        address: "",
        services: [],
        perks: [],
        totalAmount: 0,
        totalDiscount: 0,
        finalAmount: 0,
        planType: "One-Time",
        planDuration: 1,  // (1 Year = 12 * 1 = 12 Months)
        totalInstallments: 1
    });

    const [recurringServices, setRecurringServices] = useState({
        customerName: "",
        address: "",
        services: [],
        perks: [
            {
                "perkName": "Price Lock",
                isActive: true,
                perkDiscount: 0
            },
            {
                "perkName": "Recurring Service Discount",
                isActive: true,
                perkDiscount: 0
            },
            {
                "perkName": "Gutter Protection Plan",
                isActive: false,
                perkDiscount: 0
            },
            {
                "perkName": "Moss Protection Plan",
                isActive: false,
                perkDiscount: 0
            }
        ],
        totalAmount: 0,
        totalDiscount: 0,
        finalAmount: 0,
        planType: "Recurring",
        planDuration: 5, // (5 Years = 12 * 5 = 60 Months)
        totalInstallments: 48
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`https://guwiquotebuilder.com/backend/jotform-response-handler.php?submissionID=${submissionID}`);
                if (response.ok) {
                    const jsonData = await response.json();
                    setApiData(jsonData);

                    // Update customerName and address in oneTimeServices
                    setOneTimeServices(prevOneTimeServices => ({
                        ...prevOneTimeServices,
                        customerName: jsonData.content.customerInfo.customerName,
                        address: jsonData.content.customerInfo.address,
                    }));

                    // Update customerName and address in recurringServices
                    setRecurringServices(prevRecurringServices => ({
                        ...prevRecurringServices,
                        customerName: jsonData.content.customerInfo.customerName,
                        address: jsonData.content.customerInfo.address,
                    }));

                    setInitialServiceList(jsonData.content.servicesAvailable);
                    setIsSubmissionIDValid(true);
                    setIsPageLoaded(true);
                } else {
                    console.error('Failed to fetch data');
                    setIsSubmissionIDValid(false);
                    setIsPageLoaded(true);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                setIsSubmissionIDValid(false);
                setIsPageLoaded(true);
            }
        };
        fetchData();
    }, [submissionID]);

    useEffect(() => {
        setOneTimeServices(prev => ({ ...prev, services: initialServiceList.filter(service => (service.serviceCategory === 'One-Time' || service.serviceCategory === 'Both') && service.defaultSelected === true) }));
        setRecurringServices(prev => ({ ...prev, services: initialServiceList.filter(service => service.serviceCategory === 'Both' && service.defaultSelected === true) }));
    }, [initialServiceList]);

    // Function to add a service to a respective plan
    const addService = (service) => {
        // Check the serviceCategory of the service
        if (service.serviceCategory === 'One-Time') {
            // Check if the service is already in oneTimeServices
            const isServiceInOneTime = oneTimeServices.services.some(item => item.serviceName === service.serviceName);

            if (!isServiceInOneTime) {
                // If not in oneTimeServices, add it there for the first time
                setOneTimeServices(prevState => ({
                    ...prevState,
                    services: [...prevState.services, service]
                }));
                setToastMessage(`${service.serviceName} Added To One Time Pack Only`);
            } else {
                setToastMessage(`${service.serviceName} Already Added`);
            }
        } else if (service.serviceCategory === 'Both') {
            // Check if the service is already in oneTimeServices
            const isServiceInOneTime = oneTimeServices.services.some(item => item.serviceName === service.serviceName);

            if (!isServiceInOneTime) {
                // If not in oneTimeServices, add it there for the first time
                setOneTimeServices(prevState => ({
                    ...prevState,
                    services: [...prevState.services, service]
                }));
            }

            // Check if the service is already in recurringServices
            const isServiceInRecurring = recurringServices.services.some(item => item.serviceName === service.serviceName);

            if (!isServiceInRecurring) {
                // If not in recurringServices, add it there
                setRecurringServices(prevState => ({
                    ...prevState,
                    services: [...prevState.services, service]
                }));
            } else {
                // If already in recurringServices, update the quota
                const updatedRecurringServices = recurringServices.services.map(item => {
                    if (item.serviceName === service.serviceName) {
                        // Clone the service object to avoid mutation
                        const updatedService = { ...item };
                        // Increment the recurringServiceQuota by 1
                        updatedService.recurringServiceQuota += 1;
                        return updatedService;
                    }
                    return item;
                });

                setRecurringServices(prevState => ({
                    ...prevState,
                    services: updatedRecurringServices
                }));
            }
            setToastMessage(`${service.serviceName} Added To Both Plans`);
        }
    };

    // Function to remove a service from the respective plan
    const removeService = (service) => {
        // Display a confirmation dialog to confirm deletion
        const isConfirmed = window.confirm(`Are you sure you want to remove ${service.serviceName}?`);

        // Check if the user confirmed the deletion
        if (isConfirmed) {
            // Remove the service from the one-time plan
            const updatedOneTimeServices = {
                ...oneTimeServices,
                services: oneTimeServices.services.filter(item => item.serviceId !== service.serviceId)
            };

            // Remove the service from the recurring plan
            const updatedRecurringServices = {
                ...recurringServices,
                services: recurringServices.services.filter(item => item.serviceId !== service.serviceId)
            };

            // Update both one-time and recurring plans with the removed service
            setOneTimeServices(updatedOneTimeServices);
            setRecurringServices(updatedRecurringServices);

            setToastMessage(`${service.serviceName} Removed`);
        }
    };


    useEffect(() => {
        const calculateTotalAmount = (services, planDuration) =>
            services.reduce((totalAmount, service) => {
                const oneTimePrice = parseInt(service.oneTimePrice, 10) || 0; // Convert to integer, default to 0 if not a valid number
                const recurringServiceQuota = parseInt(service.recurringServiceQuota, 10) || 0; // Convert to integer, default to 0 if not a valid number

                return totalAmount + oneTimePrice * recurringServiceQuota * planDuration;
            }, 0);

        const oneTimePlanDuration = oneTimeServices.planDuration;

        // Calculate total amount for one-time services
        const oneTimeTotalAmount = calculateTotalAmount(oneTimeServices.services, oneTimePlanDuration);

        // Calculate final amount for one-time services (no discount)
        const oneTimeFinalAmount = oneTimeTotalAmount;

        // Update one-time services state
        setOneTimeServices((prevOneTimeServices) => ({
            ...prevOneTimeServices,
            totalAmount: oneTimeTotalAmount,
            totalDiscount: 0, // No discount
            finalAmount: oneTimeFinalAmount,
        }));


        // Calculate total amount and total discount for recurring services
        const recurringPlanDuration = recurringServices.planDuration;

        const recurringTotalAmount = calculateTotalAmount(recurringServices.services, recurringPlanDuration);
        let recurringTotalDiscount = 0;

        if ((recurringTotalAmount / (12 * recurringPlanDuration)) >= 83) {
            recurringTotalDiscount = 0.15; // 15% discount
        } else if ((recurringTotalAmount / (12 * recurringPlanDuration)) >= 42) {
            recurringTotalDiscount = 0.125; // 12.5% discount
        } else {
            recurringTotalDiscount = 0.10; // 10% discount
        }

        // Calculate final amount for recurring services
        const recurringFinalAmount = recurringTotalAmount * (1 - recurringTotalDiscount);

        // Check if the required services for Gutter Protection Plan are present
        const hasGutterProtectionServices = (
            recurringServices.services.some(service =>
                service.serviceName === 'Gutter Clearing' && service.recurringServiceQuota >= 2
            )
        );

        // Check if the required services for Moss Protection Plan are present
        const hasMossPreventionServices = (
            recurringServices.services.some(service =>
                service.serviceName === 'Chemical Spray' && service.recurringServiceQuota >= 1
            ) &&
            recurringServices.services.some(service =>
                service.serviceName === 'Zinc Treatment' && service.recurringServiceQuota >= 1
            )
        );

        setRecurringServices((prevRecurringServices) => {
            const updatedPerks = prevRecurringServices.perks.map((perk) => {
                if (perk.perkName === "Price Lock") {
                    return {
                        ...perk,
                        perkDescription: `This is your price for the next ${recurringPlanDuration} years. No increases, No surprises`,
                    }
                }
                if (perk.perkName === "Gutter Protection Plan") {
                    return {
                        ...perk,
                        perkDescription: hasGutterProtectionServices ? "Protection all year round from leaks and clogs, Gu-Wi takes care of your gutter system so you don't have to." : "Not applicable without additional gutter services being added.",
                        isActive: hasGutterProtectionServices, // Activate the perk if conditions are met
                    };
                }
                if (perk.perkName === "Recurring Service Discount") {
                    return {
                        ...perk,
                        perkDescription: recurringTotalDiscount ? "LIMITED TIME OFFER. You'll receive a " + (recurringTotalDiscount * 100) + "% discount on all maintenance services included in this plan and any future requested work." : "Ineligible for any discount.",
                        perkDiscount: recurringTotalDiscount,
                    };
                }
                if (perk.perkName === "Moss Protection Plan") {
                    return {
                        ...perk,
                        perkDescription: hasMossPreventionServices ? "No moss will grow for the life of this service plan, guaranteed." : "Both Zinc Treatment and Chemical Spray must be added for this Protection Plan to apply.",
                        isActive: hasMossPreventionServices, // Activate the perk if conditions are met
                    };
                }
                return perk;
            });

            return {
                ...prevRecurringServices,
                perks: updatedPerks,
                totalAmount: recurringTotalAmount,
                totalDiscount: recurringTotalDiscount,
                finalAmount: recurringFinalAmount,
            };
        });
    }, [oneTimeServices.services, recurringServices.services, oneTimeServices.planDuration, recurringServices.planDuration]);


    useEffect(() => {
        if (toastMessage) {
            // Display the toast message

            // Set a timer to clear the toast message after 3 seconds (adjust the duration as needed)
            const timer = setTimeout(() => {
                setToastMessage(null); // Reset toastMessage to null after the duration
            }, 3000); // Adjust the duration as needed (e.g., 3000 milliseconds for 3 seconds)

            // Clean up the timer when the component unmounts or when toastMessage changes
            return () => clearTimeout(timer);
        }
    }, [toastMessage]);

    // Create refs for QuoteDisplay components
    const oneTimeServiceRef = useRef(null);
    const recurringServiceRef = useRef(null);

    // Scroll to the respective QuoteDisplay component
    const scrollToQuoteDisplay = (ref) => {
        ref.current.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        isPageLoaded ? (
            isSubmissionIDValid ? (
                <div className="flex flex-col md:flex-row bg-slate-100 min-h-screen">
                    {/* Left Pane */}
                    <div className="bg-white w-full md:w-96 p-2 md:p-4">
                        <div className="bg-white p-4 rounded-lg shadow mb-4 flex flex-row-reverse md:flex-col items-center justify-between gap-2">
                            {/* Logo */}
                            <div className='cursor-pointer' onClick={() => window.open('https://www.gu-wi.com')}>
                                <img src={logo} alt="Logo" className="w-12 md:w-16" />
                            </div>
                            {/* Profile Card */}
                            <div className="flex-1 flex flex-col sm:flex-row sm:items-center sm:space-x-4">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="w-6 text-green-800">
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
                                </svg>
                                <div className='flex-1'>
                                    <p className="text-green-800 mb-2 font-bold text-md md:text-xl">Welcome, {apidata.content.customerInfo.customerName}</p>
                                    <p className="text-slate-600 text-md">{apidata.content.customerInfo.address}</p>
                                </div>
                            </div>
                        </div>
                        <div className="block md:hidden mt-4 mb-4 bg-slate-100 rounded-xl p-4">
                            <div className="md:text-start flex flex-col gap-2">
                                <div className='font-extrabold text-2xl md:text-4xl text-start xl:text-center'>
                                    <span className="text-slate-800">Hi,&nbsp;</span>
                                    <span className='text-green-800'>
                                        {apidata.content.customerInfo.customerName.split(' ').map((name, index) => {
                                            if (index === 0) {
                                                return name;
                                            } else {
                                                // Get the first character of the other names and add a period
                                                return name.charAt(0) + '.';
                                            }
                                        }).join(' ')}
                                    </span>
                                </div>
                                <div className='text-start xl:text-center'>
                                    <span className="font-extrabold text-xl md:text-2xl text-slate-800">Please design your desired Gu-Wi Maintenance Service.</span>
                                </div>
                                <div className="text-slate-600 text-justify xl:text-center">
                                    <span>
                                        The following services are available and priced specifically for your property. Please add or remove services to create your desired work order, and submit your request for a one time service or Recurring Maintenance Plan.
                                        <span className='text-green-800 font-medium'>
                                            &nbsp;All prices shown on this page are pre-tax.
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <AddServices Services={initialServiceList} onAddService={addService} />
                    </div>

                    {/* Right Pane */}
                    <div className="flex-1 p-2 md:p-4">
                        <div className="hidden md:block mt-4 mb-4">
                            <div className="md:text-start flex flex-col gap-2">
                                <div className='font-extrabold text-2xl md:text-4xl'>
                                    <span className="text-slate-800">Hi,&nbsp;</span>
                                    <span className='text-green-800'>
                                        {apidata.content.customerInfo.customerName.split(' ').map((name, index) => {
                                            if (index === 0) {
                                                return name;
                                            } else {
                                                // Get the first character of the other names and add a period
                                                return name.charAt(0) + '.';
                                            }
                                        }).join(' ')}
                                    </span>
                                </div>
                                <div>
                                    <span className="font-extrabold text-xl md:text-2xl text-slate-800">Please design your desired Gu-Wi Maintenance Service.</span>
                                </div>
                                <div className="text-slate-600">
                                    <span>
                                        The following services are available and priced specifically for your property. Please add or remove services to create your desired work order, and submit your request for a one time service or Recurring Maintenance Plan.  <span className='text-green-800 font-medium'>All prices shown on this page are pre-tax</span>.
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className='p-4 mb-4 container mx-auto bg-slate-300 rounded-lg md:rounded-full grid grid-cols-1 md:grid-cols-2 gap-2 items-center'>
                            <button className='w-full p-6 bg-white rounded-lg md:rounded-full capitalize font-bold text-xl text-slate-800' onClick={() => scrollToQuoteDisplay(oneTimeServiceRef)}>One Time Service: ${Math.round(oneTimeServices.finalAmount).toLocaleString()}</button>
                            <button className='w-full p-6 bg-white rounded-lg md:rounded-full capitalize font-bold text-xl text-slate-800' onClick={() => scrollToQuoteDisplay(recurringServiceRef)}>Maintenance Plan: ${Math.round(recurringServices.finalAmount / recurringServices.totalInstallments).toLocaleString()} Per Month</button>
                        </div>
                        <div ref={oneTimeServiceRef}>
                            <QuoteDisplay id="oneTimeService" quote={oneTimeServices} onRemoveService={removeService} />
                        </div>
                        <br /><br />
                        <div ref={recurringServiceRef}>
                            <QuoteDisplay id="recurringService" quote={recurringServices} onRemoveService={removeService} />
                        </div>
                        <ContactUsSection />
                    </div>

                    {/* Display Toast Message */}
                    {toastMessage && (
                        <Toast message={toastMessage} />
                    )}
                </div >
            ) : (
                <NotFound />
            )
        ) :
            <div className="flex items-center justify-center min-h-screen">
                {/* Loader */}
                <div role="status">
                    <svg aria-hidden="true" className="inline w-6 h-6 text-white animate-spin dark:text-black fill-white dark:fill-white" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                    </svg>
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
    );
};

export default Dashboard;
